const Footer = () => {
  return (
    <div>
      <div className="flex items-center justify-between flex-col md:flex-row gap-4">
        <div className="block">
          <div className="ml-4 flex items-center md:ml-6 space-x-4">
            <a>© Family, Inc.</a>
            <a>Terms</a>
            <a>Privacy policy</a>
          </div>
        </div>
        <div className="grow"></div>
        <div className="block">
          <div className="ml-4 flex items-center md:ml-6 space-x-3">
            <a>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 12.0607C22 6.504 17.5233 2 12 2C6.47667 2 2 6.504 2 12.0607C2 17.0833 5.656 21.2453 10.4373 22V14.9693H7.89867V12.06H10.4373V9.844C10.4373 7.32267 11.93 5.92933 14.2147 5.92933C15.308 5.92933 16.4533 6.126 16.4533 6.126V8.602H15.1913C13.9493 8.602 13.5627 9.378 13.5627 10.174V12.0607H16.336L15.8927 14.9687H13.5627V22C18.344 21.2453 22 17.0833 22 12.0607Z"
                  fill="black"
                  fillOpacity="0.5"
                />
              </svg>
            </a>
            <a>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 1.32353V16.6765C18 17.0275 17.8606 17.3641 17.6123 17.6123C17.3641 17.8606 17.0275 18 16.6765 18H1.32353C0.972508 18 0.635863 17.8606 0.387653 17.6123C0.139443 17.3641 0 17.0275 0 16.6765L0 1.32353C0 0.972508 0.139443 0.635863 0.387653 0.387653C0.635863 0.139443 0.972508 0 1.32353 0L16.6765 0C17.0275 0 17.3641 0.139443 17.6123 0.387653C17.8606 0.635863 18 0.972508 18 1.32353ZM5.29412 6.88235H2.64706V15.3529H5.29412V6.88235ZM5.53235 3.97059C5.53375 3.77036 5.49569 3.57182 5.42035 3.3863C5.34502 3.20078 5.23387 3.03191 5.09328 2.88935C4.95268 2.74678 4.78537 2.6333 4.60091 2.5554C4.41646 2.47749 4.21846 2.43668 4.01824 2.43529H3.97059C3.5634 2.43529 3.17289 2.59705 2.88497 2.88497C2.59705 3.17289 2.43529 3.5634 2.43529 3.97059C2.43529 4.37777 2.59705 4.76828 2.88497 5.05621C3.17289 5.34413 3.5634 5.50588 3.97059 5.50588C4.17083 5.51081 4.37008 5.47623 4.55696 5.40413C4.74383 5.33202 4.91467 5.2238 5.0597 5.08565C5.20474 4.94749 5.32113 4.78212 5.40223 4.59897C5.48333 4.41582 5.52755 4.21848 5.53235 4.01824V3.97059ZM15.3529 10.2071C15.3529 7.66059 13.7329 6.67059 12.1235 6.67059C11.5966 6.6442 11.0719 6.75644 10.6019 6.9961C10.1318 7.23577 9.73285 7.59448 9.44471 8.03647H9.37059V6.88235H6.88235V15.3529H9.52941V10.8476C9.49115 10.3862 9.63649 9.92835 9.93388 9.57347C10.2313 9.2186 10.6567 8.9954 11.1176 8.95235H11.2182C12.06 8.95235 12.6847 9.48177 12.6847 10.8159V15.3529H15.3318L15.3529 10.2071Z"
                  fill="black"
                  fillOpacity="0.5"
                />
              </svg>
            </a>
            <a>
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.9308 1.64161C15.6561 1.05671 14.2892 0.625776 12.8599 0.378965C12.8339 0.374202 12.8079 0.386106 12.7945 0.409914C12.6187 0.722593 12.4239 1.13051 12.2876 1.45113C10.7503 1.22099 9.22099 1.22099 7.71527 1.45113C7.57887 1.12338 7.37707 0.722593 7.20048 0.409914C7.18707 0.3869 7.16107 0.374996 7.13504 0.378965C5.70659 0.624988 4.33963 1.05592 3.06411 1.64161C3.05307 1.64637 3.04361 1.65431 3.03732 1.66462C0.444493 5.53826 -0.265792 9.31668 0.0826501 13.0483C0.0842267 13.0665 0.0944749 13.084 0.108665 13.0951C1.81934 14.3514 3.47642 15.114 5.10273 15.6196C5.12876 15.6275 5.15634 15.618 5.1729 15.5965C5.55761 15.0712 5.90054 14.5172 6.19456 13.9347C6.21192 13.9006 6.19535 13.8601 6.15989 13.8466C5.61594 13.6403 5.098 13.3887 4.59977 13.103C4.56037 13.08 4.55721 13.0236 4.59347 12.9966C4.69831 12.9181 4.80318 12.8363 4.9033 12.7538C4.92141 12.7387 4.94665 12.7356 4.96794 12.7451C8.24107 14.2395 11.7846 14.2395 15.0191 12.7451C15.0404 12.7348 15.0657 12.7379 15.0846 12.753C15.1847 12.8356 15.2895 12.9181 15.3952 12.9966C15.4314 13.0236 15.4291 13.08 15.3897 13.103C14.8914 13.3943 14.3735 13.6403 13.8288 13.8458C13.7933 13.8593 13.7775 13.9006 13.7949 13.9347C14.0952 14.5164 14.4381 15.0704 14.8157 15.5957C14.8315 15.618 14.8599 15.6275 14.8859 15.6196C16.5201 15.114 18.1772 14.3514 19.8879 13.0951C19.9028 13.084 19.9123 13.0673 19.9139 13.049C20.3309 8.73493 19.2154 4.98749 16.9568 1.66541C16.9513 1.65431 16.9419 1.64637 16.9308 1.64161ZM6.68335 10.7761C5.69792 10.7761 4.88594 9.87141 4.88594 8.76034C4.88594 7.64927 5.68217 6.74457 6.68335 6.74457C7.69239 6.74457 8.49651 7.65721 8.48073 8.76034C8.48073 9.87141 7.68451 10.7761 6.68335 10.7761ZM13.329 10.7761C12.3435 10.7761 11.5316 9.87141 11.5316 8.76034C11.5316 7.64927 12.3278 6.74457 13.329 6.74457C14.338 6.74457 15.1421 7.65721 15.1264 8.76034C15.1264 9.87141 14.338 10.7761 13.329 10.7761Z"
                  fill="black"
                  fillOpacity="0.5"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
