import { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import OtpInput from "react-otp-input";
import { FiPhone } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { LuWallet } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import { Button } from "../../common/buttons";
import PhoneInput from "react-phone-number-input";
import { TextInput } from "..";
import {
  ConfirmationResult,
  RecaptchaVerifier,
  getAuth,
  sendSignInLinkToEmail,
  signInWithPhoneNumber,
} from "firebase/auth";
import "firebase/remote-config";
import "firebase/performance";
import { auth } from "../../main";
import toast from "react-hot-toast";
// import ConnectWallet from "../../common/ConnectWallet";
import { ShortAddress } from "../../common/components";
import {
  // AbiCoder,
  BrowserProvider,
  Interface,
  concat,
  ethers,
  keccak256,
  toUtf8Bytes,
} from "ethers";
import axios from "axios";
import "react-phone-number-input/style.css";
// TypeScript interfaces
interface AuthModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

interface AuthState {
  step: number;
  contactMethod: "email" | "phone";
  emailOrPhone: string;
  otp: string;
  isOtpSent: boolean;
}

const AuthModal = ({ isOpen, handleClose }: AuthModalProps) => {
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult>();
  const [claims] = useState<any>();
  const [wallet, setWallet] = useState("");
  console.log(claims, auth?.currentUser?.getIdTokenResult());

  const [authState, setAuthState] = useState<AuthState>({
    step: 1,
    contactMethod: "email",
    emailOrPhone: "",
    otp: "",
    isOtpSent: false,
  });

  // Handlers
  const handleStepChange = (newStep: number) => {
    setAuthState((prevState) => ({ ...prevState, step: newStep }));
  };

  const handleStateChange = (changes: Partial<AuthState>) => {
    setAuthState((prevState) => ({ ...prevState, ...changes }));
  };

  const onCloseHandler = () => {
    handleClose();
    handleStateChange({
      step: 1,
      emailOrPhone: "",
      otp: "",
      isOtpSent: false,
    });
  };

  const handleSelectContactMethod = (method: "email" | "phone") => {
    handleStateChange({ contactMethod: method });
    handleStepChange(authState.step + 1);
  };

  const handleSendVerificationCode = () => {
    // Add logic for data validation and sending verification code

    // TODO
    if (authState.contactMethod === "email") {
      authenticate();
    } else {
      setUpRecaptha(authState.emailOrPhone);
      handleStateChange({ isOtpSent: true });
    }
  };

  const handleMinting = async () => {
    try {
      // Check if MetaMask is installed
      if (window.lukso) {
        // Create a Web3Provider with MetaMask's provider
        const provider = new BrowserProvider(window.lukso);

        // Get the signer from the provider
        const signer = await provider.getSigner();

        // Data to sign (you can customize this)
        const message = "Kindly sign to mint the Genesis collection NFT";

        // Sign the message using await
        const signature = await signer.signMessage(toUtf8Bytes(message));
        const recoveredAddress = ethers.recoverAddress(
          keccak256(
            concat([
              "0x19457468657265756d205369676e6564204d6573736167653a0a3232",
              toUtf8Bytes(message),
            ])
          ),
          signature
        );

        const result = await provider.call({
          to: wallet,
          data: new Interface([
            "function isValidSignature(bytes32,bytes)",
          ]).encodeFunctionData("isValidSignature(bytes32,bytes)", [
            keccak256(
              concat([
                "0x19457468657265756d205369676e6564204d6573736167653a0a3232",
                toUtf8Bytes(message),
              ])
            ),
            signature,
          ]),
        });

        // const recoveredAddress =
        //   ethers.utils.computeAddress(recoveredAddress);

        console.log("Signature:", signature, recoveredAddress, result);
        try {
          let response = await axios.post(
            "http://127.0.0.1:5001/familylyx/us-central1/mint",
            { uid: auth.currentUser?.uid, address: wallet }
          );
          console.log(response.data);
          toast.success("Minting Successfull", { duration: 3000 });
        } catch (err: any) {
          toast.error(err?.message, { duration: 3000 });
        }
      } else {
        throw new Error("MetaMask not detected. Please install it.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleOtpVerification = async () => {
    // Handle OTP Verification
    try {
      const result = await confirmationResult?.confirm(authState.otp);
      console.log(result, result?.user);
      handleClose();
      toast.success("Verification Successful");
    } catch (err) {
      console.log(err);
    }
    handleStepChange(authState.step + 1);
  };

  const handleConnectWallet = async () => {
    // Handle wallet connection
    // ConnectWallet()
    if (typeof window.lukso !== "undefined") {
      // Request account access
      try {
        let accounts = await window.lukso.request({
          method: "eth_requestAccounts",
        });

        const account = accounts[0];
        setWallet(account);
        handleStepChange(authState.step + 1);
        console.log("Connected to wallet. Account:", account);
      } catch (error: any) {
        console.error("Wallet connection error:", error);
      }
    } else {
      console.error("Universal Profile not detected. Please install it.");
    }
  };

  async function authenticate() {
    localStorage.setItem("auth-email", authState.emailOrPhone);

    try {
      await sendSignInLinkToEmail(getAuth(), authState.emailOrPhone, {
        url: window.location.href,
        handleCodeInApp: true,
      });
      // setLoading({
      //   status: 1,
      //   message: "We've sent a link on your email. Check your inbox",
      // });
      toast.success("We've sent a link on your email. Check your inbox", {
        duration: 3000,
      });
      handleClose();
    } catch (err: any) {
      toast.error(err.message, { duration: 3000 });
    }
  }

  // useEffect(() => {
  //   const updateCliam = async () => {
  //     setClaims((await auth?.currentUser?.getIdTokenResult())?.claims);
  //   };
  //   updateCliam();
  // }, [auth.currentUser]);
  async function setUpRecaptha(number: string) {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      { size: "invisible" },
      auth
    );
    try {
      const widgetId = await recaptchaVerifier.render();
      grecaptcha.reset(widgetId);
    } catch (err) {
      console.log(err);
    }
    return setConfirmationResult(
      await signInWithPhoneNumber(auth, number, recaptchaVerifier)
    );
    // return setClaims((await auth?.currentUser?.getIdTokenResult())?.claims);
  }

  // Render step content
  const renderStepContent = () => {
    const { step, contactMethod, emailOrPhone, otp, isOtpSent } = authState;

    switch (step) {
      case 1:
        return (
          <div className="flex flex-col gap-4">
            <Button onClick={() => handleSelectContactMethod("email")}>
              <div className="flex items-center justify-center gap-4">
                <HiOutlineMail size="1.2rem" />
                <span>Verify with Email Address</span>
              </div>
            </Button>
            <Button onClick={() => handleSelectContactMethod("phone")}>
              <div className="flex items-center justify-center gap-4">
                <FiPhone size="1.2rem" />
                <span>Verify with Phone Number</span>
              </div>
            </Button>
          </div>
        );
      case 2:
        return (
          <>
            {!isOtpSent ? (
              contactMethod !== "email" ? (
                <PhoneInput
                  international
                  defaultCountry="AU"
                  value={emailOrPhone}
                  onChange={(e) => handleStateChange({ emailOrPhone: e })}
                />
              ) : (
                <TextInput
                  value={emailOrPhone}
                  onChange={(e) =>
                    handleStateChange({ emailOrPhone: e.target.value })
                  }
                  placeholder={"Email Address"}
                />
              )
            ) : (
              <div className="text-center font-bold">
                {authState.emailOrPhone}
              </div>
            )}
            {isOtpSent && (
              <div className="mt-6 ml-1 flex items-center flex-col">
                <span>Enter Verification Code Sent</span>
                <OtpInput
                  containerStyle=" my-2 -ml-2 mt-4"
                  inputStyle={{ width: "32px" }}
                  value={otp}
                  onChange={(otpValue) => handleStateChange({ otp: otpValue })}
                  numInputs={6}
                  renderSeparator={<span></span>}
                  inputType="number"
                  renderInput={({ className, ...props }) => (
                    <input
                      className="border-2 rounded mx-2 py-2 outline-none border-gray-300"
                      {...props}
                    />
                  )}
                />
              </div>
            )}
            {isOtpSent ? (
              <div className="mt-4">
                <Button onClick={handleOtpVerification} variant="dark">
                  Log in
                </Button>
              </div>
            ) : (
              <div className="flex gap-2 mt-4">
                <Button onClick={() => handleStepChange(authState.step - 1)}>
                  Back
                </Button>
                <Button
                  id="sign-in-button"
                  onClick={
                    isOtpSent
                      ? handleOtpVerification
                      : handleSendVerificationCode
                  }
                >
                  Next
                </Button>
              </div>
            )}
          </>
        );
      case 3:
        return claims?.claimer ? (
          <div className="mt-6">
            <p className="pt-1 text-lg pb-8 text-center">
              Connect to your wallet
            </p>
            <Button onClick={handleConnectWallet}>
              <div className="flex items-center justify-center gap-4">
                <LuWallet size="1.2rem" />
                <span>Connect Profile</span>
              </div>
            </Button>
          </div>
        ) : (
          <div>You are not eligible to claim</div>
        );
      case 4:
        return (
          <div className="my-8 text-center text-lg">
            <p>Connected Wallet: {ShortAddress({ address: wallet })}</p>
            <div className="mt-5">
              <Button variant="dark" onClick={() => handleMinting()}>
                Mint
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  // Main render
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-8 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mb-4 flex items-center justify-between">
                  <p className="text-lg font-semibold">Verification</p>
                  <IoClose
                    size="1.3rem"
                    onClick={onCloseHandler}
                    className="cursor-pointer"
                  />
                </div>
                {renderStepContent()}
              </Dialog.Panel>
            </Transition.Child>
          </div>
          <div id="recaptcha-container"></div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AuthModal;
