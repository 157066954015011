import { ReactElement, useEffect, useState } from "react";
import AuthModal from "../../components/Claim/AuthModal";
import { Button } from "../../common/buttons";
import { Accordion } from "../../components/common";
import HeroVideo from "../../assets/claim/hero-intro.mp4";
import HeroBorderImg from "../../assets/claim/border.svg";
import logo from "../../logo.svg";

import Footer from "./Footer";
import { auth } from "../../main";
import {
  User,
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailLink,
} from "firebase/auth";
import { LuWallet } from "react-icons/lu";
import { ShortAddress } from "../../common/components";
import axios from "axios";
import toast from "react-hot-toast";

interface FAQItem {
  question: string;
  answer: ReactElement;
}

const ClaimIntro: React.FC = () => {
  const [authModal, setAuthModal] = useState<boolean>(false);
  const [claims, setClaims] = useState<any>();
  const [wallet, setWallet] = useState("");
  const [, setUser] = useState<User>();
  const [minting, setMinting] = useState<boolean>(false);
  const [claimed, setClaimed] = useState<boolean>(false);
  const live = import.meta.env.VITE_LIVE;

  const faqData: FAQItem[] = [
    {
      question: "What is the Genesis Products Airdrop?",
      answer: (
        <p>
          Holders of our Genesis Phygital will be airdropped their Genesis
          Products for the <strong>LUKSO Mainnet</strong>.
        </p>
      ),
    },
    {
      question: "How can I participate in the Genesis Phygitals Airdrop?",
      answer: (
        <>
          <ul className="list-disc list-inside">
            <li>
              Make sure you have a <strong>Universal Profile</strong> and the{" "}
              <strong>Extension</strong> installed.
            </li>
            <li>
              Verify your identity by using the email or phone number you used
              when you ordered your Genesis Phygitals.
            </li>
            <li>
              Check your email for your <strong>Claim Link</strong>.
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "What are the dates and timelines for the airdrop?",
      answer: (
        <p>
          The closing date for the airdrop will be{" "}
          <strong>February 20 2024</strong>.
        </p>
      ),
    },
    {
      question: "What 'Genesis Products' will be airdropped?",
      answer: (
        <>
          <p>
            <strong>Genesis Perk:</strong> 1 Free Order on all Family releases.
          </p>
          <p>
            <strong>Genesis Phygital:</strong> Digital Certificate of the
            Physical.
          </p>
        </>
      ),
    },
    {
      question: "Are there any risks involved in participating?",
      answer: (
        <p>
          We've mitigated all the possible risks and have ensured utmost safety
          for our Genesis Holders, make sure you only use the right links and
          contact us on{" "}
          <a
            href="mailto:support@familyyx.com"
            className="text-blue-600 hover:text-blue-800 font-medium"
          >
            support@familyyx.com
          </a>{" "}
          for any concerns or questions.
        </p>
      ),
    },
    {
      question: "Can the airdrop rewards be traded, and if so, how?",
      answer: (
        <>
          <p>
            The Genesis Perk can be traded on any marketplace in the LUKSO
            ecosystem but the Genesis Phygital can only be traded on the{" "}
            <strong>Family Marketplace</strong>.
          </p>
        </>
      ),
    },
  ];
  // useEffect(() => {
  //   console.log("current user", getAuth());
  // });

  const handleConnectWallet = async () => {
    // Handle wallet connection
    // ConnectWallet()
    if (typeof window.lukso !== "undefined") {
      // Request account access

      try {
        if (window.lukso.chainId !== "0x2a") {
          try {
            window.lukso.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: "0x2a" }], // Replace with the desired chain ID
            });
          } catch (err) {
            console.log(err);
          }
        }
        let accounts = await window.lukso.request({
          method: "eth_requestAccounts",
        });

        const account = accounts[0];
        setWallet(account);
        // handleStepChange(authState.step + 1);
        // console.log("Connected to wallet. Account:", account);
      } catch (error: any) {
        console.error("Wallet connection error:", error);
      }
    } else {
      console.error("Universal Profile not detected. Please install it.");
    }
  };

  const handleMinting = async () => {
    setMinting(true);
    try {
      // Check if MetaMask is installed

      try {
        let response = await axios.post(
          import.meta.env.VITE_MINT_FUNCTION ??
            "http://127.0.0.1:5001/familylyx/us-central1/mint",
          { uid: auth.currentUser?.uid, address: wallet }
        );
        setClaims((await auth?.currentUser?.getIdTokenResult())?.claims);
        setClaimed(true);
        console.log(response);
        toast.success("Minting Successfull", { duration: 3000 });
      } catch (err: any) {
        import.meta.env.VITE_MINT_FUNCTION ??
          "http://127.0.0.1:5001/familylyx/us-central1/mint";
        toast.error(err?.message, { duration: 3000 });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setMinting(false);
    }
  };

  useEffect(() => {
    const updateCliam = async () => {
      setClaims((await auth?.currentUser?.getIdTokenResult())?.claims);
    };
    // console.log("useeffect", auth.currentUser);
    updateCliam();
  }, [auth.currentUser]);

  useEffect(() => {
    const email = localStorage.getItem("auth-email");

    if (email && isSignInWithEmailLink(getAuth(), window.location.href)) {
      signInWithEmailLink(getAuth(), email as string, window.location.href)
        .then(() => {
          localStorage.removeItem("auth-email");
        })
        .catch(console.log);
    }
  }, []);

  useEffect(() => {
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        setUser(user);
      }
    });
  }, []);

  return (
    <>
      <div>
        <section className="min-h-screen flex flex-wrap md:flex-nowrap">
          <div
            className={`w-full md:w-1/2 p-6 flex flex-col justify-center items-center  min-h-[30rem]`}
          >
            <img
              src={logo}
              className="w-32 md:w-40 lg:w-52"
              alt="Family Logo"
            />
            <h1 className="long-title text-center  leading-[4.5rem] md:leading-tight text-6xl md:text-9xl mt-10">
              Genesis Claim
            </h1>

            <div className="w-full mt-10 md:mt-10 ml-0 flex justify-center">
              {auth?.currentUser ? (
                claims?.claimed || claimed ? (
                  <div>User already minted</div>
                ) : claims?.claimer ? (
                  !wallet ? (
                    <div className="mt-6">
                      <p className="pt-1 text pb-3 text-center">
                        Connect to your wallet
                      </p>
                      <Button variant="dark" onClick={handleConnectWallet}>
                        <div className="flex items-center text-sm justify-center gap-4">
                          <LuWallet size="1.2rem" />
                          <span>Connect Profile</span>
                        </div>
                      </Button>
                    </div>
                  ) : (
                    <div className="my-8 text-center text-lg">
                      <p>
                        Connected Wallet: {ShortAddress({ address: wallet })}
                      </p>
                      <div className="mt-5">
                        <Button variant="dark" onClick={() => handleMinting()}>
                          {minting ? "Minting" : "Mint"}
                        </Button>
                      </div>
                    </div>
                  )
                ) : (
                  <div>
                    This airdrop is reserved exclusively for our Genesis NFT
                    holders. If you possess a Genesis NFT and are experiencing
                    difficulties with the claim process, we're here to assist
                    you. Please reach out to us at support@familylyx.com, and
                    we'll ensure you receive your rightful airdrop promptly.
                  </div>
                )
              ) : (
                <div className="w-[30%] text-[20px]">
                  <Button
                    variant="dark"
                    onClick={() => setAuthModal(true)}
                    disabled={!live}
                  >
                    Verify
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="w-full md:w-1/2 relative">
            <video
              src={HeroVideo}
              loop
              autoPlay
              playsInline
              muted
              className="max-full block h-full object-cover w-full"
            ></video>
            <img
              src={HeroBorderImg}
              className="h-full absolute top-0 left-[-2px] rotate-180 hidden md:block"
              alt=""
            />
          </div>
        </section>

        <section className="bg-gray-50 py-16 px-4 ">
          <Accordion title="FAQ's" data={faqData} />
        </section>

        <div className="p-6 bg-gray-50">
          <Footer />
        </div>
      </div>

      <AuthModal isOpen={authModal} handleClose={() => setAuthModal(false)} />
    </>
  );
};

export default ClaimIntro;
